<template>
      <b-button class="btn" :style="btnStyle(changeStyle)" @click="click"><i :class="icon"></i>{{ text }}</b-button>
</template>
<script>
export default {

  name: "btnComponent",
  props: ["changeStyle"],
  methods:{
    btnStyle(style){
        let styleChange;
        // console.log(style);
        if(style === 'search'){
            styleChange = 'background:#29A2F2;border-color:#29A2F2;'
            this.text = ' ค้นหา'
            this.icon = 'uil uil-search-alt'
        }else if(style === 'submit'){
           styleChange = 'background:#34c38f;border:0'
            this.text = ' บันทีก'
            this.icon = ''
        }else if(style === 'submitEdit'){
           styleChange = 'background:#34c38f;border:0'
            this.text = ' เสร็จสิ้น-กลับสู่หน้าหลัก'
            this.icon = ''
        }else{
            styleChange = 'background:#f46a6a;border:0'
            this.text = ' ไม่ถูกต้อง'
            this.icon = ''
        }
        return styleChange;
    },
    click () {
        this.$emit('click');
      }
  }

};
</script>
