<template>
  <div>{{
      change(date)
    }}</div>
</template>
<script>
export default {
  name: "ChangeDate",
  props: ["date"],
  methods:{
    change(dateChange){
      // console.log(dateChange);
      const today = new Date(dateChange);
      // const changeDate = date.toLocaleDateString("th-TH", {
      //   year: "numeric",
      //   month: "numeric",
      //   day: "numeric", 
      // })
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear()+543;

      const date = `${dd}/${mm}/${yyyy}`
      
      const changeDate = date;
      return changeDate;
    }
  }
};
</script>
