
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="row justify-content-md-center mt-4">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สาขา:</label>
                      <multiselect
                        v-model="branchIdSearch"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2 text-end">
                      เลขที่การติดต่อ:
                      <b-form-input
                        v-model="filter.ctJobCode"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2">
                      ชื่อ:
                      <b-form-input
                        v-model="filter.nameTh"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2">
                      นามสกุล:
                      <b-form-input
                        v-model="filter.familyNameTh"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center">
                        ประเภทการติดต่อ:</label
                      >
                      <multiselect
                        v-model="filter.contactType"
                        :options="contactTypeOption"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สถานะ:</label>
                      <multiselect
                        v-model="filter.status"
                        :options="statusOption"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-12 text-end">
                    <btnComponent @click="handleSearch" changeStyle="search">
                    </btnComponent
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                    <router-link
                      :to="{ name: 'add-sale-contactJob' }"
                      class="btn btn-primary"
                    >
                      <i class="mdi mdi-plus me-1"></i
                      >เพิ่มการติดต่อ </router-link
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                    <router-link
                      :to="{ name: 'contactJob-calendar' }"
                      class="btn btn-primary"
                    >
                      <i class="uil-calendar-alt"></i>
                    </router-link>
                  </div>
                  <!-- <div class="row float-end"></div> -->
                </div>
              </div>
            </div>
            <div class="row justify-content-md-center mt-2">
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-12 col-md-12">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        แสดงผล&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          @input="handlePageChange"
                        ></b-form-select
                        >&nbsp;รายการ
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <tableData :fields="fields" :items="rowData">
                <template #cell(sellPrice)="rowData">
                  {{ Number(rowData.item.sellPrice).toLocaleString() }}
                </template>
                <template #cell(index)="rowData" v-if="this.currentPage > 1">
                  {{ rowData.index + 1 + (currentPage * perPage - perPage) }}
                </template>
                <template #cell(index)="rowData" v-else>
                  {{ rowData.index + 1 }}
                </template>
                <template #cell(ctJobDate)="rowData">
                  <changeDate :date="rowData.item.ctJobDate"></changeDate>
                </template>
                <template #cell(status)="rowData">
                  <span
                    class="badge bg-warning font-size-12"
                    v-if="rowData.item.status === 'DFT'"
                  >
                    ร่าง
                  </span>
                  <span
                    class="badge bg-info font-size-12"
                    v-if="rowData.item.status === 'WIP'"
                  >
                    รอดำเนินการ
                  </span>
                  <span
                    class="badge bg-success font-size-12"
                    v-if="rowData.item.status === 'SCS'"
                  >
                    เสร็จสิ้น
                  </span>
                  <span
                    class="badge bg-danger font-size-12 ms-2"
                    v-if="rowData.item.status === 'L'"
                  >
                    Lost
                  </span>
                  <span
                    class="badge bg-danger font-size-12 ms-2"
                    v-if="rowData.item.status === 'CAN'"
                  >
                    ยกเลิก
                  </span>
                </template>

                <template #cell(action)="rowData">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                        :to="{
                          name: 'edit-sale-contactJob',
                          params: { ctJobId: rowData.item.ctJobIdBase64 },
                        }"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>

                      <a
                        v-if="rowData.item.status != 'SCS'"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="alert(rowData.item.ctJobId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </tableData>

              <br />

              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="col-md-12">
                    <div class="align-items-center">
                      หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                      {{ totalRecord }} รายการ
                    </div>
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecord"
                          :per-page="perPage"
                          @change="handleChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import tableData from "@/components/tablecomponent";
import btnComponent from "@/components/btnComponent.vue";
import Multiselect from "vue-multiselect";
import ChangeDate from "@/components/changeDateComponent";

// import branchComponent from "@/components/branchComponent.vue";

export default {
  page: {
    title: appConfig.contactCustomer,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    btnComponent,
    Multiselect,
    ChangeDate,
  },

  data() {
    return {
      loading: undefined,

      title: appConfig.contactCustomer,
      items: [
        {
          text: "ฝ่ายขาย",
          active: true,
        },
        {
          text: appConfig.contactCustomer,
          active: true,
        },
      ],
      // partId: this.$route.params.partId,
      model: [],
      totalPage: "",
      branchId: [],
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      rowData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: {
        ctJobCode: "",
        nameTh: "",
        branchId: "",
        familyNameTh: "",
        contactType: "",
        status:""
      },
      contactTypeOption: [],
      branchIdSearch: "",
      localDataBranchId: [],
      statusOption: [
        { id: "DFT", nameTh: "ร่าง" },
        { id: "WIP", nameTh: "รอดำเนินการ" },
        { id: "SCS", nameTh: "เสร็จสิ้น" },
        { id: "CAN", nameTh: "ยกเลิก" },
      ],
      filterOn: [],
      localData: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "ctJobCode",
          label: "เลขที่การติดต่อ",
          sortable: true,
        },
        {
          key: "nameTh",
          label: "ชื่อ",
          sortable: true,
        },
        {
          key: "familyNameTh",
          label: "นามสกุล",
          sortable: true,
        },

        {
          key: "ctJobDate",
          label: "วันที่",
          sortable: true,
        },
        {
          key: "contactTypeNameTh",
          label: "ประเภท",
          sortable: true,
        },
        {
          key: "licensePlate",
          label: "ทะเบียนรถ",
          sortable: true,
        },

        {
          key: "status",
          label: "สถานะ",
        },
        {
          key: "branchNameTh",
          label: "สาขา",
          sortable: true,
        },
        {
          key: "action",
          label: appConfig.action,
        },
      ],
    };
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowParts.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.rowParts = this.items.length;
  },
  created() {
    this.getLocalData();
    this.getData();
    this.getContactType();
  },
  methods: {
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;

      const localDataBranch = [];
      const localDataBranchId = [];
      // const localBranchIdItem ={};
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.localDataBranchId = localDataBranchId;

      // console.log("สาขาาาาาา", this.localDataBranchId);
    },

    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranchId;
      } else {
        this.localDataBranchId = [this.branchIdSearch.branchId];
      }
      this.getData();
    },
    onFiltered(filteredItems) {
      this.rowData = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },

    getData: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/sale-contact-job", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranchId,
            ctJobCode: this.filter.ctJobCode,
            nameTh: this.filter.nameTh,
            familyNameTh: this.filter.familyNameTh,
            ctJobTypeId: this.filter.contactType ? this.filter.contactType.ctJobTypeId:"",
            status: this.filter.status ? this.filter.status.id :"",
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getContactType: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/contact-job-types-c", {})
        .then((response) => {
          this.contactTypeOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    delete: function (rowData) {
      this.Data = rowData;
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/sale-contact-job/delete", {
          data: {
            ctJobId: this.Data,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getData();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowData) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.delete(rowData);
          }
        });
    },
  },
  middleware: "authentication",
};
</script>
