<template>

  <div class="table-responsive mb-0">
    <b-table
      :items="items"
      :fields="fields"
      responsive="sm"
     
    >
      <template v-for="(_, action) of $scopedSlots" v-slot:[action]="scope">
        <slot :name="action" v-bind="scope" />
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  name: "tableData",
  props: {
    fields: Array,
    items: Array,
  },
  computed: {
    tableHeader() {
      return this.fields || [];
    },
    tableData() {
      return this.items || [];
    },
  },
  data() {
    return {
      selected: [],
      overlayFlag: false,
    };
  },
  methods: {
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
  },
};
</script>

<style></style>
